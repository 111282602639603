import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FooterBackground from '../../images/winfire-footer-background.png';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundImage: `url(${FooterBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  container: {
    marginTop: '3rem',
    paddingBottom: '3rem',
  },
  Link: {
    color: '#fff',
    fontSize: '1.14rem',
    lineHeight: '1.5',
    textDecoration: 'none',
  },
  Footeritem: {
    marginBottom: '10px !important',
  },
  FooteritemSocial: {
    marginBottom: '10px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  FooteritemSocialMobile: {
    marginBottom: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  LinkSocial: {
    color: '#fff',
    fontSize: '1.14rem',
    lineHeight: '1.5',
    textDecoration: 'none',
    paddingLeft: '1rem',
  },
  icon: {
    width: '30px',
    height: '30px',
  },
  footerColumn: {
    [theme.breakpoints.down('md')]: {
      padding: '2rem !important',
    },
  },
}));

function CoreValue() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item lg={1} />
        <Grid item container lg={10} spacing={4}>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3} className={classes.footerColumn}>
            <Typography variant="h6">
              <strong> Gói sản phẩm </strong>
            </Typography>
            <br />
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/san-pham/ong-den-chuyen-dung"> Gói Ống đen và Mạ kẽm chuyên dụng </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/san-pham/san-sang-thi-cong"> Gói Sẵn sàng thi công </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/san-pham/ong-co-ren-ranh"> Gói Ống có ren rãnh </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3} className={classes.footerColumn}>
            <Typography variant="h6">
              <strong> Thông tin </strong>
            </Typography>
            <br />
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/"> Trang chủ </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/winfire"> Giới thiệu </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/chung-chi-chat-luong"> Chứng chỉ chất lượng </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/ban-tin"> Bản tin </Link>
            </Typography>
            <Typography className={classes.Footeritem}>
              <Link className={classes.Link} href="/lien-he"> Liên hệ </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3} className={classes.footerColumn}>
            <Typography variant="h6">
              <strong> Follow Us </strong>
            </Typography>
            <br />
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <FacebookIcon fontSize="large" />
                <Link className={classes.LinkSocial} target="_blank" href="/"> Facebook </Link>
              </div>
            </div>
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <YouTubeIcon fontSize="large" />
                <Link className={classes.LinkSocial} target="_blank" href="https://youtube.com/channel/UCL3f56BywXCIiF_A7LrmCMw"> Youtube </Link>
              </div>
            </div>
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <WhatsAppIcon fontSize="large" />
                <Link className={classes.LinkSocial} target="_blank" href="https://api.whatsapp.com/send?phone=84966884650"> WhatsApp </Link>
              </div>
            </div>
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <MailIcon fontSize="large" />
                <Link className={classes.LinkSocial} target="_blank" rel="noopener" href="mailto:sales@chinhdaisteel.com,marketing@chinhdaisteel.com"> Mail </Link>
              </div>
            </div>
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <img src="https://img.icons8.com/ios-filled/50/000000/zalo.png" alt="zalo" className={classes.icon} style={{ marginBottom: '0px', backgroundColor: 'white', marginLeft: '1px' }} />
                <Link className={classes.LinkSocial} target="_blank" href="http://zalo.me/+84913236789"> Zalo </Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3} className={classes.footerColumn}>
            <Typography variant="h6">
              <strong> Liên hệ  </strong>
            </Typography>
            <br />
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <PhoneIcon fontSize="large" />
                <Typography className={classes.LinkSocial}>
                  (+84) 913 236 789 / (+84) 976 208 688
                </Typography>
              </div>
            </div>
            <div className={classes.FooteritemSocial}>
              <div className={classes.FooteritemSocialMobile}>
                <LocationOnIcon fontSize="large" />
                <Typography className={classes.LinkSocial}>
                  Công ty TNHH Công Nghiệp Chính Đại,
                  Thôn Hoằng, xã Lạc Đạo, huyện Văn Lâm, tỉnh Hưng Yên
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item lg={1} />
      </Grid>
    </section>
  );
}

export default withStyles(useStyles)(CoreValue);
