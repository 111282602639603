import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Languages from './languagesvn';

const cdilogohori = 'https://static.chinhdaisteel.com/Winfire/logo/winfire.png';
const winfirelogo = 'https://static.chinhdaisteel.com/Winfire/logo/winfire_logo_vertical.png';
const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '1.14rem',
    color: 'black',
    borderRadius: 0,
    lineHeight: 1.5,
    backgroundColor: '#f8f8f8',
    marginRight: '1rem',
    '&:hover': {
      color: 'orange',
      backgroundColor: '#f8f8f8',
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.14rem',
    color: 'black',
    textDecoration: 'none',
  },
  placeholder: {
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
  toolbar: {
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    justifyContent: 'space-between',
    backgroundColor: '#f8f8f8',
    borderRadius: 0,
    lineHeight: 1.5,
    borderColor: 'black',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.1), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
  },
  left: {
    flex: 1,
  },
  right: {
  },
  buttonlogo: {
    width: '200px',
    height: '50px',
    alignItems: 'center',
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
  },
  logoimg: {
    width: '100%',
    height: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  menuMobileTitle: {
    color: '#110e3a',
    fontWeight: '600',
    fontSize: '1.14rem',
    cursor: 'pointer',
    '&:hover': {
      color: 'orange',
    },
    marginLeft: theme.spacing(4),
  },
  homebutton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '1.14rem',
    color: '#110e3a',
    borderRadius: 0,
    lineHeight: 1.5,
    backgroundColor: '#f8f8f8',
    borderColor: '#a5a5a5',
    '&:hover': {
      color: 'orange',
      // borderColor: '#e8e8e8',
      backgroundColor: '#f8f8f8',
      boxShadow: 'none',
    },
  },
  mobileDropMenu: {
    backgroundColor: '#f8f8f8',
    display: 'none',
  },
  menuTitle: {
    fontSize: '1.14rem',
    fontWeight: 'bold',
  },
  menuItem: {
    [theme.breakpoints.up('md')]: {
      marginRight: '1%',
      display: 'flex',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '3%',
      display: 'flex',
    },
    [theme.breakpoints.between(1280, 1400)]: {
      display: 'flex',
      marginRight: '1%',
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '5%',
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  navbarHidden: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoCdiHorizontal: {
    maxWidth: '100px',
    height: 'auto',
    marginBottom: '0px !important',
  },
  logoCdiHorizontalLg: {
    maxWidth: '120px',
    height: 'auto',
    marginBottom: '0px !important',
  },
  menuItemLogo: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  menuItemLogoLg: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      marginRight: '2%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  logoMobile: {
    maxWidth: '100px',
    height: 'auto',
    marginBottom: '0px !important',
  },
}));

export default function Navbarvn() {
  const classes = useStyles();
  const sections = [
    {
      title: 'GIỚI THIỆU', url: '/winfire',
    },
    {
      title: 'SẢN PHẨM', url: '/san-pham',
    },
    {
      title: 'ĐỐI TÁC', url: '/doi-tac',
    },
    {
      title: 'BLOG', url: '/ban-tin',
    },
    {
      title: 'LIÊN HỆ', url: '/lien-he',
    },
  ];

  //
  const handleClick1 = () => {
    window.location.href = '/';
  };

  //
  const handleClick3 = () => {
    window.location.href = '/san-pham';
  };
  //

  const showMobileDropMenu = () => {
    const DropMenu = document.getElementById('mobileDropMenu').style.display;
    if (DropMenu === 'block') {
      document.getElementById('mobileDropMenu').style.display = 'none';
    } else if (document.getElementById('mobileDropMenu').style.display === 'none') {
      document.getElementById('mobileDropMenu').style.display = 'block';
    }
    if (document.getElementById('mobileDropMenu').style.display === '') {
      document.getElementById('mobileDropMenu').style.display = 'block';
    }
  };
  const gotoDoitacMobile = () => {
    window.location.href = '/doi-tac';
  };
  const gotoBlogMobile = () => {
    window.location.href = '/ban-tin';
  };
  const gotoContactMobile = () => {
    window.location.href = '/lien-he';
  };
  const gotoGioithieuMobile = () => {
    window.location.href = '/winfire';
  };
  const gotoEn = () => {
    window.location.href = '/en';
  };

  const hideHeader = () => {
    document.addEventListener('scroll', () => {
      const header = document.getElementById('navbarHeader');
      const scrollLogo = document.getElementById('scrolldownLogo');
      if (header !== null) {
        const height = header.offsetHeight;
        const scroll = window.scrollY;
        if (scroll > height) {
          header.style.display = 'none';
          scrollLogo.style.display = 'flex';
        } else if (window.innerWidth > 1279) {
          header.style.display = 'flex';
          scrollLogo.style.display = 'none';
        }
      }
    });
  };
  //
  return (
    <>
      <Hidden mdUp>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>
            <div className={classes.left}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                href="/"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <img className={classes.logoMobile} src={cdilogohori} alt="chinh dai steel logo" />
              </Button>
            </div>
            <div className={classes.right}>
              <IconButton onClick={showMobileDropMenu}>
                <MenuIcon fontSize="large" style={{ color: '#227dbf' }} />
              </IconButton>
            </div>
          </Toolbar>
          <div className={classes.mobileDropMenu} id="mobileDropMenu">
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={handleClick1}
            >
              TRANG CHỦ
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={gotoGioithieuMobile}
            >
              GIỚI THIỆU
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={handleClick3}
            >
              SẢN PHẨM
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={gotoDoitacMobile}
            >
              ĐỐI TÁC
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={gotoBlogMobile}
            >
              BLOG
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={gotoContactMobile}
            >
              LIÊN HỆ
            </Typography>
            <br />
            <Typography
              className={classes.menuMobileTitle}
              onClick={gotoEn}
            >
              ENGLISH
            </Typography>
            <br />
          </div>
        </AppBar>
      </Hidden>
      <Hidden smDown>
        <AppBar position="fixed" elevation={0} onLoad={hideHeader}>
          <Grid id="navbarHeader" container style={{ backgroundColor: '#fff', color: '#000' }} className={classes.navbarHidden}>
            <Grid item lg={1} />
            <Grid item lg={1}>
              <BootstrapButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                href="/"
                style={{ backgroundColor: '#fff' }}
              >
                <img src={winfirelogo} className={classes.logoimg} alt="" />
              </BootstrapButton>
            </Grid>
            <Grid item lg={5} style={{ display: 'flex', alignItems: 'center', color: '#545454' }}>
              <div style={{ marginLeft: '1rem' }}>
                <Typography variant="h6" style={{ lineHeight: '1.5', color: '#545454' }}>
                  <b>THÉP CHÍNH ĐẠI - THÀNH DANH THÉP </b>
                </Typography>
                <Typography variant="h6" style={{ lineHeight: '1.5' }}>
                  Tiên phong ống cứu hỏa chuyên dụng ở Việt Nam
                </Typography>
              </div>
            </Grid>
            <Grid item lg={4} style={{ display: 'flex', alignItems: 'center', color: '#545454' }}>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Typography variant="h6"> Telephone: (+84) 913 236 789 </Typography>
                <Typography variant="h6"> Email: trucnh@chinhdaisteel.com </Typography>
              </div>
            </Grid>
            <Grid item lg={1} />
          </Grid>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item lg={1} />
              <Grid item container lg={10}>
                <div className={classes.menuItemLogo}>
                  <BootstrapButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    color="primary"
                    href="/"
                  >
                    <img className={classes.logoCdiHorizontal} src={cdilogohori} alt="chinh dai steel logo" />
                  </BootstrapButton>
                </div>
                <div id="scrolldownLogo" className={classes.menuItemLogoLg}>
                  <BootstrapButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    color="primary"
                    href="/"
                  >
                    <img className={classes.logoCdiHorizontalLg} src={cdilogohori} alt="chinh dai steel logo" />
                  </BootstrapButton>
                </div>
                {sections.map((section) => (
                  <div className={classes.menuItem} key={section.url}>
                    <BootstrapButton
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      color="primary"
                      href={section.url}
                      className={classes.menuTitle}
                    >
                      {section.title}

                    </BootstrapButton>
                  </div>
                ))}
              </Grid>
              <Grid item lg={1} />
            </Grid>
            <div className={classes.right}>
              <Languages />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.placeholder} />
      </Hidden>

    </>
  );
}
